<template>
  <div class="row">
    <div class="col-md-6">
      <h2 class="font-bold">Bem vindo ao iMat - Projetos</h2>

      <p>
        Controle de orçamento e custos.
      </p>

      <p>
        Gestão de aquisições: solicitações, cotação e compras.
      </p>

      <p>
        Acompanhamento de contratos e recebimentos.
      </p>

<!--       <p v-if="showing == 'login'">
        Não possui uma conta?

        <a class="btn btn-primary block full-width m-b"
           :href="signup_url"
          >
          Crie a sua conta!

        </a>
      </p>
 -->
      <p v-if="showing == 'signup'">
        Já possui uma conta?
        <a class="btn btn-primary block full-width m-b"
           :href="login_url"
        >Faça login</a>
      </p>
    </div>

    <login-form v-if="showing == 'login'"
                :options="{login_url: this.login_url}"
    ></login-form>
    <signup-form v-if="showing == 'signup'">

    </signup-form>
    <remember-form v-if="showing == 'remember'">

    </remember-form>

  </div>
</div>
</template>

<script>

import LoginForm       from './login_form.vue';
import SignUpForm      from './signup_form.vue';
import RememberForm    from './remember_form.vue';

export default {
  props: ['options'],
  components: {
      'login-form':    LoginForm,
      'signup-form':    SignUpForm,
      'remember-form':    RememberForm
  },
  data: function() {
    return {
      showing: 'login',

      auth_token: '',
      login_url: this.options.login_url,
      signup_url: '/signup/sign_up'

      // email: 'gustavo@43t.com.br',
      // password: '963258741'
    }
  },
  methods: {
    setSignup: function () {
      this.showing = 'signup'
    },
    setLogin: function () {
      this.showing = 'login'
    }
  },
  created: function() {
    // this.token = this.$store.getters['CurrentUserStore/token']

    console.log('Btn ok!')
    this.auth_token = document.querySelector("meta[name=csrf-token]").content

  },
  mounted: function() {
debugger
    this.showing = this.options.action ;
  }

}
</script>
