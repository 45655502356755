<template>
  <div class="col-md-6">
    <div class="ibox-content">
      <form class="m-t" role="form"
            method="post"
            :action="opts.signup_url">

        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="authenticity_token"
               :value="opts.form_token">

        <div class="form-group">
          <label class="string required" id="user_email" for="user_email"
                 style="width: 100%;text-align: left !important;">Email do novo usuário</label>
          <input type="email" class="form-control"
                 placeholder="Digite seu email" required=""
                 name="user[email]"
          >
        </div>
        <div class="form-group">
          <label class="password required" id="user_password" for="user_password"
                 style="width: 100%;text-align: left !important;">Senha</label>
          <input type="password" class="form-control"
                 placeholder="Digite sus senha"
                 required=""
                 autocomplete="new-password"
                 name="user[password]"
          >
        </div>

        <button type="submit"
                class="btn btn-primary block full-width m-b">
          Criar conta
        </button>

        <a @click="showRemember">
          <small>Esqueceu a sua senha?</small>
        </a>
<!--
        <p class="text-muted text-center"
           style="margin-bottom: 2px">
          <small>Ainda não é usuário?</small>
        </p>
        <a class="btn btn-sm btn-white btn-block"
           href="register.html">Cadastrar um usuário
        </a>
 -->
      </form>

    </div>
  </div>
</template>

<script>

export default {
  components: {
    // Layout
  },

  data: function() {
    return {
      email: '',
      password: '',
      opts: {
        signup_url: '/signup',
        form_token: ''

      }
    }
  },
  created: function() {
    this.opts.form_token = document.querySelector("meta[name=csrf-token]").content
  },
  methods: {
    showRemember: function() {
      this.$parent.showing = 'remember'
    }
  }

}
</script>
