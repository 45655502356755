console.log('Init load Devise App')

import Vue from 'vue/dist/vue.esm';

// import i18n_plug   from './plugins/ti18n.js';
// Vue.use(i18n_plug) ;

import DeviseIndex      from '../packs/components/devise/devise_index.vue';

const app = new Vue({
  name: 'devise_app',

  data: {
    name: 'devise_app',
    show_devise: false,
    devise_form: 'login'
  },
  components: {
    'devise-index' : DeviseIndex
  },
  created: function() {
    // this.initUserStore()
  },
  methods: {
    // initUserStore: function() {
    //   this.$store.dispatch('CurrentUserStore/initCurrUser')
    // },
    alert: function(type, msg) {
      toastr[type](msg);
      return msg;
    }
  }
}).$mount('#devise-app')

window.devise_vm = app

